// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import '../../../styles/hcp.css';
// const PrescriptionManager = () => {
//     console.log("PrescriptionManager");
//     const [prescriptions, setPrescriptions] = useState([]);
//     const [selectedPrescription, setSelectedPrescription] = useState(null);
//     const [formData, setFormData] = useState({
//         patient_id: '',
//         provider_id: '',
//         medication_name: '',
//         dosage: '',
//         duration: '',
//         refill_info: ''
//     });
//     const [error, setError] = useState('');

//     useEffect(() => {
//         fetchPrescriptions();
//     }, []);

//     const fetchPrescriptions = async () => {
//         try {
//             const response = await axios.get('https://rxn9279.uta.cloud/api/prescriptions');
//             setPrescriptions(response.data);
//         } catch (error) {
//             setError('Failed to fetch prescriptions');
//         }
//     };

//     const handleFormChange = (event) => {
//         setFormData({
//             ...formData,
//             [event.target.name]: event.target.value
//         });
//     };

//     const handleSubmit = async (event) => {
//         event.preventDefault();
//         try {
//             const provider_id = localStorage.getItem('userId');
//             if (!selectedPrescription) {
//                 const response = await axios.post('https://rxn9279.uta.cloud/api/prescriptions', { ...formData, provider_id });
//                 setPrescriptions([...prescriptions, response.data]);
//             } else {
//                 await axios.put(`https://rxn9279.uta.cloud/api/prescriptions/${selectedPrescription.id}`, formData);
//                 fetchPrescriptions();
//             }
//             clearForm();
//         } catch (error) {
//             setError('Failed to submit prescription');
//         }
//     };

//     const handleSelectPrescription = async (id) => {
//         try {
//             const response = await axios.get(`https://rxn9279.uta.cloud/api/prescriptions/${id}`);
//             setSelectedPrescription(response.data);
//             setFormData(response.data);
//         } catch (error) {
//             setError('Failed to fetch prescription details');
//         }
//     };

//     const handleDelete = async (id) => {
//         try {
//             await axios.delete(`https://rxn9279.uta.cloud/api/prescriptions/${id}`);
//             setPrescriptions(prescriptions.filter(p => p.id !== id));
//         } catch (error) {
//             setError('Failed to delete prescription');
//         }
//     };

//     const clearForm = () => {
//         setSelectedPrescription(null);
//         setFormData({
//             patient_id: '',
//             provider_id: '',
//             medication_name: '',
//             dosage: '',
//             duration: '',
//             refill_info: ''
//         });
//     };

//     return (
//         <>
//             <div className="prescription-manager">
//                 {error && <p className="error-message">{error}</p>}
//                 <h3>E-prescriptions</h3>
//                 <form onSubmit={handleSubmit} className="prescription-form">
//                     <input type="text" name="patient_id" value={formData.patient_id} onChange={handleFormChange} placeholder="Patient ID" required />
//                     <input type="text" name="medication_name" value={formData.medication_name} onChange={handleFormChange} placeholder="Medication Name" required />
//                     <input type="text" name="dosage" value={formData.dosage} onChange={handleFormChange} placeholder="Dosage" required />
//                     <input type="text" name="duration" value={formData.duration} onChange={handleFormChange} placeholder="Duration" required />
//                     <input type="text" name="refill_info" value={formData.refill_info} onChange={handleFormChange} placeholder="Refill Information" />
//                     <button type="submit">Submit</button>
//                 </form>
//                 <button type="button" onClick={clearForm} className="clear-button">Clear</button>
//                 <ul className="prescriptions-list">
//                     {prescriptions.map(prescription => (
//                         <li key={prescription.id}>
//                             {prescription.medication_name} - {prescription.dosage}
//                             <button onClick={() => handleSelectPrescription(prescription.id)} className="edit">Edit</button>
//                             <button onClick={() => handleDelete(prescription.id)} className="delete">Delete</button>
//                         </li>
//                     ))}
//                 </ul>
//             </div>
//         </>
//     );
// }
// export default PrescriptionManager;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../../styles/hcp.css';

const PrescriptionManager = () => {
    const [prescriptions, setPrescriptions] = useState([]);
    const [selectedPrescription, setSelectedPrescription] = useState(null);
    const [formData, setFormData] = useState({
        patient_id: '',
        provider_id: '',
        medication_name: '',
        dosage: '',
        duration: '',
        refill_info: ''
    });
    const [users, setUsers] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchPrescriptions();
        fetchUsers();
    }, []);

    const fetchPrescriptions = async () => {
        try {
            const response = await axios.get('https://rxn9279.uta.cloud/api/prescriptions');
            setPrescriptions(response.data);
        } catch (error) {
            setError('Failed to fetch prescriptions');
        }
    };

    const fetchUsers = async () => {
        try {
            const response = await axios.get('https://rxn9279.uta.cloud/api/users');
            const patients = response.data.filter(user => user.role === 'patient');
            setUsers(patients);
        } catch (error) {
            setError('Failed to fetch patients');
        }
    };

    const handleFormChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const provider_id = localStorage.getItem('userId');
            if (!selectedPrescription) {
                const response = await axios.post('https://rxn9279.uta.cloud/api/prescriptions', { ...formData, provider_id });
                setPrescriptions([...prescriptions, response.data]);
            } else {
                await axios.put(`https://rxn9279.uta.cloud/api/prescriptions/${selectedPrescription.id}`, formData);
                fetchPrescriptions();
            }
            clearForm();
        } catch (error) {
            setError('Failed to submit prescription');
        }
    };



    const handleSelectPrescription = async (id) => {
        try {
            const response = await axios.get(`https://rxn9279.uta.cloud/api/prescriptions/${id}`);
            setSelectedPrescription(response.data);
            setFormData(response.data);
        } catch (error) {
            setError('Failed to fetch prescription details');
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://rxn9279.uta.cloud/api/prescriptions/${id}`);
            setPrescriptions(prescriptions.filter(p => p.id !== id));
        } catch (error) {
            setError('Failed to delete prescription');
        }
    };

    const clearForm = () => {
        setSelectedPrescription(null);
        setFormData({
            patient_id: '',
            provider_id: '',
            medication_name: '',
            dosage: '',
            duration: '',
            refill_info: ''
        });
    };

    return (
        <>
            <div className="prescription-manager">
                {error && <p className="error-message">{error}</p>}
                <h3>E-prescriptions</h3>
                <form onSubmit={handleSubmit} className="prescription-form">
                    <select name="patient_id" value={formData.patient_id} onChange={handleFormChange} required>
                        <option value="">Select Patient</option>
                        {users.map(user => (
                            <option key={user.id} value={user.id}>{user.email}</option>
                        ))}
                    </select>
                    <input type="text" name="medication_name" value={formData.medication_name} onChange={handleFormChange} placeholder="Medication Name" required />
                    <input type="text" name="dosage" value={formData.dosage} onChange={handleFormChange} placeholder="Dosage" required />
                    <input type="text" name="duration" value={formData.duration} onChange={handleFormChange} placeholder="Duration" required />
                    <input type="text" name="refill_info" value={formData.refill_info} onChange={handleFormChange} placeholder="Frequency" />
                    <button type="submit">Submit</button>
                </form>
                <button type="button" onClick={clearForm} className="clear-button">Clear</button>
                <ul className="prescriptions-list">


                    {prescriptions.map(prescription => (
                        <li key={prescription.id}>
                            {prescription.medication_name} - {prescription.dosage} - {prescription.refill_info} {prescription.duration}
                            <button onClick={() => handleSelectPrescription(prescription.id)} className="edit">Edit</button>
                            <button onClick={() => handleDelete(prescription.id)} className="delete">Delete</button>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default PrescriptionManager;
