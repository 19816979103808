import axios from 'axios';

export const baseURL = 'https://rxn9279.uta.cloud/api';

// User endpoints
export const getUsers = () => axios.get(`${baseURL}/users`);
export const getUser = (id) => axios.get(`${baseURL}/users/${id}`);
export const createUser = (data) => axios.post(`${baseURL}/users`, data);
export const updateUser = (id, data) => axios.put(`${baseURL}/users/${id}`, data);
export const deleteUser = (id) => axios.delete(`${baseURL}/users/${id}`);
export const loginUser = (data) => axios.post(`${baseURL}/login`, data);

// Healthcare Provider endpoints
export const getProviders = () => axios.get(`${baseURL}/providers`);
export const getProvider = (id) => axios.get(`${baseURL}/providers/${id}`);
export const createProvider = (data) => axios.post(`${baseURL}/providers`, data);
export const updateProvider = (id, data) => axios.put(`${baseURL}/providers/${id}`, data);
export const deleteProvider = (id) => axios.delete(`${baseURL}/providers/${id}`);

// Patient endpoints
export const getPatients = () => axios.get(`${baseURL}/patients`);
export const getPatient = (id) => axios.get(`${baseURL}/patients/${id}`);
export const createPatient = (data) => axios.post(`${baseURL}/patients`, data);
export const updatePatient = (id, data) => axios.put(`${baseURL}/patients/${id}`, data);
export const deletePatient = (id) => axios.delete(`${baseURL}/patients/${id}`);

// Appointment endpoints
export const getAppointments = () => axios.get(`${baseURL}/appointments`);
export const getAppointment = (id) => axios.get(`${baseURL}/appointments/${id}`);
export const createAppointment = (data) => axios.post(`${baseURL}/appointments`, data);
export const updateAppointment = (id, data) => axios.put(`${baseURL}/appointments/${id}`, data);
export const deleteAppointment = (id) => axios.delete(`${baseURL}/appointments/${id}`);

// Prescription endpoints
export const getPrescriptions = () => axios.get(`${baseURL}/prescriptions`);
export const getPrescription = (id) => axios.get(`${baseURL}/prescriptions/${id}`);
export const createPrescription = (data) => axios.post(`${baseURL}/prescriptions`, data);
export const updatePrescription = (id, data) => axios.put(`${baseURL}/prescriptions/${id}`, data);
export const deletePrescription = (id) => axios.delete(`${baseURL}/prescriptions/${id}`);

// Health Record endpoints
export const getHealthRecords = () => axios.get(`${baseURL}/health-records`);
export const getHealthRecord = (id) => axios.get(`${baseURL}/health-records/${id}`);
export const createHealthRecord = (data) => axios.post(`${baseURL}/health-records`, data);
export const updateHealthRecord = (id, data) => axios.put(`${baseURL}/health-records/${id}`, data);
export const deleteHealthRecord = (id) => axios.delete(`${baseURL}/health-records/${id}`);

// Medication Reminder endpoints
export const getMedicationReminders = () => axios.get(`${baseURL}/medication-reminders`);
export const getMedicationReminder = (id) => axios.get(`${baseURL}/medication-reminders/${id}`);
export const createMedicationReminder = (data) => axios.post(`${baseURL}/medication-reminders`, data);
export const updateMedicationReminder = (id, data) => axios.put(`${baseURL}/medication-reminders/${id}`, data);
export const deleteMedicationReminder = (id) => axios.delete(`${baseURL}/medication-reminders/${id}`);

// System Administrator endpoints
export const getAdministrators = () => axios.get(`${baseURL}/administrators`);
export const getAdministrator = (id) => axios.get(`${baseURL}/administrators/${id}`);
export const createAdministrator = (data) => axios.post(`${baseURL}/administrators`, data);
export const updateAdministrator = (id, data) => axios.put(`${baseURL}/administrators/${id}`, data);
export const deleteAdministrator = (id) => axios.delete(`${baseURL}/administrators/${id}`);

// Facility endpoints
export const getFacilities = () => axios.get(`${baseURL}/facilities`);
export const getFacility = (id) => axios.get(`${baseURL}/facilities/${id}`);
export const createFacility = (data) => axios.post(`${baseURL}/facilities`, data);
export const updateFacility = (id, data) => axios.put(`${baseURL}/facilities/${id}`, data);
export const deleteFacility = (id) => axios.delete(`${baseURL}/facilities/${id}`);

// Staff Management endpoints
export const getStaff = () => axios.get(`${baseURL}/staff`);
export const getStaffMember = (id) => axios.get(`${baseURL}/staff/${id}`);
export const createStaffMember = (data) => axios.post(`${baseURL}/staff`, data);
export const updateStaffMember = (id, data) => axios.put(`${baseURL}/staff/${id}`, data);
export const deleteStaffMember = (id) => axios.delete(`${baseURL}/staff/${id}`);
