const facilityManagement = [
    {
        "id": "1001",
        "facilityName": "Greenwood Hospital",
        "firstName": "John",
        "lastName": "Smith",
        "services": "Emergency Care, Surgery, Pediatrics",
        "status": "Active"
      },
      {
        "id": "1002",
        "facilityName": "Sunset Clinic",
        "firstName": "Emily",
        "lastName": "Johnson",
        "services": "Primary Care, Internal Medicine, Laboratory",
        "status": "Active"
      },
      {
        "id": "1003",
        "facilityName": "Evergreen Medical Center",
        "firstName": "Michael",
        "lastName": "Williams",
        "services": "Radiology, Cardiology, Oncology",
        "status": "Inactive"
      },
      {
        "id": "1004",
        "facilityName": "Maplewood Health Center",
        "firstName": "Sarah",
        "lastName": "Brown",
        "services": "Dental Care, Physical Therapy, Dermatology",
        "status": "Inactive"
      },
      {
        "id": "1005",
        "facilityName": "Pineview Nursing Home",
        "firstName": "David",
        "lastName": "Jones",
        "services": "Geriatrics, Rehabilitation, Palliative Care",
        "status": "Active"
      }
  ]

const staffData = [
        {
          "id": "staff001",
          "role": "Nurse",
          "name": "Emily Johnson",
          "email": "emily.johnson@example.com",
          "phone": "+1 (555) 123-4567",
          "accessLevel": "Standard"
        },
        {
          "id": "staff002",
          "role": "Doctor",
          "name": "Dr. Michael Smith",
          "email": "michael.smith@example.com",
          "phone": "+1 (555) 987-6543",
          "accessLevel": "Admin"
        },
        {
          "id": "staff003",
          "role": "Technician",
          "name": "Sarah Lee",
          "email": "sarah.lee@example.com",
          "phone": "+1 (555) 321-7890",
          "accessLevel": "Standard"
        },
        {
          "id": "staff004",
          "role": "Receptionist",
          "name": "David Brown",
          "email": "david.brown@example.com",
          "phone": "+1 (555) 456-7890",
          "accessLevel": "Limited"
        },
        {
          "id": "staff005",
          "role": "Pharmacist",
          "name": "Jennifer Garcia",
          "email": "jennifer.garcia@example.com",
          "phone": "+1 (555) 789-0123",
          "accessLevel": "Standard"
        }    
]

const incidentResponseData = [
  {
    "id": "IR001",
    "reportedIssue": "System unresponsive",
    "reportDate": "2024-03-01",
    "status": "Resolved",
    "severity": "High",
    "description": "Users reported system becoming unresponsive during patient data entry. Investigations revealed a memory leak issue which was promptly addressed by restarting the affected services.",
    "reportedProblem": "Resolved"
  },
  {
    "id": "IR002",
    "reportedIssue": "Incorrect patient records",
    "reportDate": "2024-03-03",
    "status": "In Progress",
    "severity": "Medium",
    "description": "Several instances of patient records showing incorrect medical histories. Investigation ongoing to identify the root cause, suspected to be a data synchronization issue between different modules of the system.",
    "reportedProblem": "In Progress"
  },
  {
    "id": "IR003",
    "reportedIssue": "System downtime",
    "reportDate": "2024-03-05",
    "status": "Open",
    "severity": "High",
    "description": "The system experienced unexpected downtime affecting access to critical patient information. Initial investigations suggest a network failure. Technicians are working to restore services.",
    "reportedProblem": "Open"
  },
  {
    "id": "IR004",
    "reportedIssue": "Slow response times",
    "reportDate": "2024-03-06",
    "status": "Resolved",
    "severity": "Low",
    "description": "Users reported sluggish performance while navigating through the system. Upon investigation, it was found to be due to an overloaded database server. Optimization measures were applied resulting in improved response times.",
    "reportedProblem": "Resolved"
  },
  {
    "id": "IR005",
    "reportedIssue": "Security breach",
    "reportDate": "2024-03-08",
    "status": "Open",
    "severity": "Critical",
    "description": "An unauthorized access attempt was detected in the system logs, potentially compromising patient data. Security team initiated investigation and implemented additional security measures to mitigate further risks.",
    "reportedProblem": "Open"
  }
]

export default {
    facilityManagement,
    staffData,
    incidentResponseData
}

