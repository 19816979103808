import React from 'react';
import MedicationDispensation from './MedicationDispensation'
import MedicationHistory from './MedicationHistory'
import Communication from './Communication'
import Header from '../Header'
import "./index.css";

class Phamacist extends React.Component {
  state = {
    currentComponent: 'MedicationDispensation',
    isSideNavOpen: false
  }

  handleToggle = (value) => {
    this.setState({ currentComponent: value });
  }

  toggleSideNav = () => {
    this.setState({ isSideNavOpen: !this.state.isSideNavOpen })
  }

  renderActiveComponent = (currentComponent) => {
    switch (currentComponent) {
      case "MedicationDispensation": return <MedicationDispensation />;
      case "MedicationHistory": return <MedicationHistory />;
      case "Communication": return <Communication />;
      default: return <h1 />;
    }
  };

  render() {
    const { currentComponent, isSideNavOpen } = this.state;
    return (
      <div className="health-care-admin">
        <Header />
        <div className='row'>
          <ul>
            <li><a className={currentComponent === 'MedicationDispensation' ? 'active' : ''} onClick={() => this.handleToggle('MedicationDispensation')}>Medication Dispensation</a></li>
            <li><a className={currentComponent === 'MedicationHistory' ? 'active' : ''} onClick={() => this.handleToggle('MedicationHistory')}>Medication History</a></li>
            <li><a className={currentComponent === 'Communication' ? 'active' : ''} onClick={() => this.handleToggle('Communication')}>Communication</a></li>
          </ul>
          <div className="user-data">
            <div className='users-info'>
              {isSideNavOpen && <ul className='side-nav'>
                <li className='close-btn' onClick={() => this.toggleSideNav()}>X</li>
                <li><a className={currentComponent === 'MedicationDispensation' ? 'active' : ''} onClick={() => this.handleToggle('MedicationDispensation')}>Medication Dispensation</a></li>
                <li><a className={currentComponent === 'MedicationHistory' ? 'active' : ''} onClick={() => this.handleToggle('MedicationHistory')}>Medication History</a></li>
                <li><a className={currentComponent === 'Communication' ? 'active' : ''} onClick={() => this.handleToggle('Communication')}>Communication</a></li>
              </ul>}
              <span className='sidenav-btn' onClick={() => this.toggleSideNav()}>=</span>
              {this.renderActiveComponent(currentComponent)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Phamacist;