import React, { useState, useEffect } from 'react';

const AppointmentTable = () => {
    const [appointments, setAppointments] = useState([]);

    useEffect(() => {
        fetch('https://rxn9279.uta.cloud/api/appointments')
            .then(response => response.json())
            .then(data => {
                const formattedData = data.map(appointment => ({
                    id: appointment.id,
                    providerId: appointment.provider_id,
                    patientId: appointment.patient_id,
                    time: new Date(appointment.appointment_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                    date: new Date(appointment.appointment_time).toLocaleDateString(),
                    status: appointment.status
                }));
                setAppointments(formattedData);
            })
            .catch(error => console.error('Error fetching appointments:', error));
    }, []);

    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th>Provider ID</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {appointments.map((appointment, index) => (
                        <tr key={index}>
                            <td>{appointment.providerId}</td>
                            <td>{appointment.date}</td>
                            <td>{appointment.time}</td>
                            <td>{appointment.status}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AppointmentTable;
