import React, { useState, useEffect } from 'react';
import axios from 'axios';

function DataOversight() {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        fetchUsers();
        const intervalId = setInterval(() => {
            fetchUsers();
        }, 30000);

        return () => clearInterval(intervalId);
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axios.get('https://rxn9279.uta.cloud/api/users');
            setUsers(response.data.map(user => ({
                ...user,
                updatedRecently: isUpdatedRecently(user.updated_at)
            })));
        } catch (error) {
            console.error('Failed to fetch users:', error);
        }
    };

    const isUpdatedRecently = (updatedTime) => {
        const oneDayAgo = new Date(Date.now() - 86400000);
        return new Date(updatedTime) >= oneDayAgo;
    };

    return (
        <div>
            <h2>Data Oversight Dashboard</h2>
            <h3>Activity in last 24 hours</h3>
            {users.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Status</th>
                            <th>Last Updated</th>
                            <th>Recent Update</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user, index) => (
                            <tr key={index} style={{ backgroundColor: user.updatedRecently ? '#ffff99' : 'white' }}>
                                <td>{user.email}</td>
                                <td>{user.role}</td>
                                <td>{user.status}</td>
                                <td>{new Date(user.updated_at).toLocaleString()}</td>
                                <td>{user.updatedRecently ? 'Recently Updated' : 'No Recent Updates'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No user data available.</p>
            )}
        </div>
    );
}

export default DataOversight;
