import React, { useState } from 'react';
import Header from '../../Header';
import UserManagement from './user_management';
import ProviderManagement from './provider_management';
import DataOversight from './data_oversight';
import SystemConfiguration from './system_configuration';

function AdminPage() {
    const [activeComponent, setActiveComponent] = useState('');

    const renderComponent = () => {
        switch (activeComponent) {
            case 'userManagement':
                return <UserManagement />;
            case 'providerManagement':
                return <ProviderManagement />;
            case 'systemConfiguration':
                return <SystemConfiguration />;
            case 'dataOversight':
                return <DataOversight />;
            default:
                return <div>Select a component from the menu</div>;
        }
    };

    return (
        <div>
            <Header />
            <h1>Admin Dashboard</h1>
            <nav>
                <button onClick={() => setActiveComponent('userManagement')}>User Management</button>
                <button onClick={() => setActiveComponent('providerManagement')}>Provider Management</button>
                <button onClick={() => setActiveComponent('systemConfiguration')}>System Configuration</button>
                <button onClick={() => setActiveComponent('dataOversight')}>Data Oversight</button>
            </nav>
            <div>
                {renderComponent()}
            </div>
        </div>
    );
}

export default AdminPage;
