import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';

function UserManagement() {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [form, setForm] = useState({ email: '', password: '', role: '' });

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        const response = await axios.get('https://rxn9279.uta.cloud/api/users');
        setUsers(response.data);
    };

    const handleFormChange = (event) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (selectedUser) {
            await axios.put(`https://rxn9279.uta.cloud/api/users/${selectedUser.id}`, form);
        } else {
            await axios.post('https://rxn9279.uta.cloud/api/users', form);
        }
        fetchUsers();
        setForm({ email: '', password: '', role: '' });
        setSelectedUser(null);
    };

    const handleEdit = (user) => {
        setSelectedUser(user);
        setForm(user);
    };

    const handleDelete = async (id) => {
        await axios.delete(`https://rxn9279.uta.cloud/api/users/${id}`);
        fetchUsers();
    };

    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(users.map(user => ({
            Email: user.email,
            Role: user.role
        })));
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Users");
        XLSX.writeFile(wb, "UserManagement.xlsx");
    };

    return (
        <div>
            <h2>User Management</h2>
            <button onClick={exportToExcel}>Export to Excel</button>
            <form onSubmit={handleSubmit}>
                <input
                    type="email"
                    name="email"
                    value={form.email}
                    onChange={handleFormChange}
                    placeholder="Email"
                    required
                />
                <input
                    type="password"
                    name="password"
                    value={form.password}
                    onChange={handleFormChange}
                    placeholder="Password"
                    required={!selectedUser}
                />
                <select name="role" value={form.role} onChange={handleFormChange} required>
                    <option value="">Select Role</option>
                    <option value="patient">Patient</option>
                    <option value="healthcareprovider">Healthcare Provider</option>
                    <option value="admin">Admin</option>
                </select>
                <button type="submit">{selectedUser ? 'Update User' : 'Add User'}</button>
            </form>

            <table>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.id}>
                            <td>{user.email}</td>
                            <td>{user.role}</td>
                            <td>
                                <button onClick={() => handleEdit(user)}>Edit</button>
                                <button onClick={() => handleDelete(user.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default UserManagement;