import React from 'react';
import Header from './Header';
import HeroSection from './HeroSection';
import StatsSection from './StatsSection';
import ConsultationSection from './ConsultationSection';
import Footer from './Footer';
import Sidebar from './SideBar';

function Home() {
  return (
    <>
      <Header />
      <HeroSection />
      <StatsSection />
      <ConsultationSection />

      <Footer />
    </>
  );
}

export default Home;
