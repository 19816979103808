import React from 'react';
import './Appointment.css'; // Make sure to create this CSS file
import Sidebar from './SideBar';
const Appointments = () => {
  // Mock data for appointments

  const facilityDetails = {
    name: 'Hospital A',
    contactInfo: '555-1234',
    address: 'AAA',
    // Add other details you might want to include
  };
  const appointments = [
    { id: 1, doctor: 'Sarah Clarke', date: '09/02/2020', time: '11:30-12:00 AM', duration: '30 mins' },
    { id: 2, doctor: 'Sarah Clarke', date: '09/02/2020', time: '11:30-12:00 AM', duration: '30 mins' },
    { id: 3, doctor: 'Sarah Clarke', date: '09/02/2020', time: '11:30-12:00 AM', duration: '30 mins' },
    { id: 4, doctor: 'Sarah Clarke', date: '09/02/2020', time: '11:30-12:00 AM', duration: '30 mins' },
    { id: 5, doctor: 'Sarah Clarke', date: '09/02/2020', time: '11:30-12:00 AM', duration: '30 mins' },
  ];

  return (
    <div  style={{marginLeft: "150px", padding: "1rem",width: "calc(100% - 190px)", minHeight: "100vh"}}  className="appointments-container">
      <Sidebar/>
      <h2>Facility Details</h2>
      <div className="patient-details">
        <h2>{facilityDetails.name}</h2>
        <p>Contact: {facilityDetails.contactInfo}</p>
        <p>Address: {facilityDetails.address}</p>
      </div>
      <h2>Appointments</h2>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Doctor</th>
            <th>Date</th>
            <th>Time</th>
            <th>Duration</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {appointments.map((appointment) => (
            <tr key={appointment.id}>
              <td>{appointment.id}</td>
              <td>{appointment.doctor}</td>
              <td>{appointment.date}</td>
              <td>{appointment.time}</td>
              <td>{appointment.duration}</td>
              <td><button type="submit">Schedule</button></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Appointments;
