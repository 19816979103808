import React, { useState } from 'react';
import './Prescriptions.css'; // Make sure to create this CSS file
import Sidebar from './SideBar';
const Prescriptions = () => {
  // This would likely be fetched from an API in a real app
  const [prescriptions, setPrescriptions] = useState([
    {
      id: 1,
      medicine: 'Amoxicillin',
      dosage: '500mg',
      prescribed_date: '12/02/2024',
      frequency: 'Three times a day',
      duration: '7 days'
    },
    {
      id: 2,
      medicine: 'Ibuprofen',
      dosage: '200mg',
      prescribed_date: '12/02/2024',
      frequency: 'Every 4-6 hours',
      duration: '5 days'
    },
    // ...more prescriptions
  ]);

  return (
    <div style={{marginLeft: "150px", padding: "1rem",width: "calc(100% - 190px)", minHeight: "100vh"}}   className="prescriptions-container">
        <Sidebar/>
      <h2>Prescriptions</h2>
      <table className="prescriptions-table">
        <thead>
          <tr>
            <th>Medicine</th>
            <th>Dosage</th>
            <th>Prescribed Date</th>
            <th>Frequency</th>
            <th>Duration</th>
          </tr>
        </thead>
        <tbody>
          {prescriptions.map((prescription) => (
            <tr key={prescription.id}>
              <td>{prescription.medicine}</td>
              <td>{prescription.dosage}</td>
              <td>{prescription.prescribed_date}</td>
              <td>{prescription.frequency}</td>
              <td>{prescription.duration}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Prescriptions;
