import React from 'react';
import data from '../healthAdminData'
import './FacilityManagement.css'

const FacilityManagement = () => {
  const facilityManagement = data.facilityManagement || [];

  return (
    <div className="facility-management">
      <h2>Facility Management</h2>
      <div className='table-content'>
      <table className='user-table'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Facility Name</th>
            <th>Services</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {facilityManagement.map((facility, index) => (
            <tr key={index}>
              <td>{facility.id}</td>
              <td>{facility.firstName} {facility.lastName}</td>
              <td>{facility.facilityName}</td>
              <td>{facility.services}</td>
              <td>{facility.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default FacilityManagement;
