import React, { useState, useEffect } from 'react';
import axios from 'axios';

const HealthRecordsManager = () => {
    const [records, setRecords] = useState([]);
    const [patients, setPatients] = useState([]);
    const [form, setForm] = useState({ patient_id: '', record_type: '', description: '' });
    const [editingId, setEditingId] = useState(null);

    useEffect(() => {
        console.log("user role is ", localStorage.getItem('userRole'));

        fetchRecords();
        if (localStorage.getItem('userRole') === 'healthcareprovider') {

            fetchPatients();
        }
    }, []);

    const fetchRecords = async () => {
        try {
            const response = await axios.get('https://rxn9279.uta.cloud/api/health-records');
            setRecords(response.data);
        } catch (error) {
            console.error('Failed to fetch records', error);
        }
    };

    const fetchPatients = async () => {
        try {
            const response = await axios.get('https://rxn9279.uta.cloud/api/users');
            const patientList = response.data.filter(user => user.role === 'patient');
            setPatients(patientList);
        } catch (error) {
            console.error('Failed to fetch patients', error);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setForm({ ...form, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const recordToSubmit = { ...form };
        if (!recordToSubmit.patient_id) {
            alert('Please select a patient.');
            return;
        }
        if (editingId) {
            await updateRecord(editingId, recordToSubmit);
        } else {
            await addRecord(recordToSubmit);
        }
        setForm({ patient_id: '', record_type: '', description: '' });
        setEditingId(null);
    };

    const addRecord = async (record) => {
        try {
            const response = await axios.post('https://rxn9279.uta.cloud/api/health-records', record);
            setRecords([...records, response.data]);
        } catch (error) {
            console.error('Failed to add record', error);
        }
    };

    const updateRecord = async (id, record) => {
        try {
            const response = await axios.put(`https://rxn9279.uta.cloud/api/health-records/${id}`, record);
            const updatedRecords = records.map(r => r.id === id ? response.data : r);
            setRecords(updatedRecords);
        } catch (error) {
            console.error('Failed to update record', error);
        }
    };

    const editRecord = (record) => {
        setForm({ ...record, patient_id: record.patient_id.toString() });
        setEditingId(record.id);
    };

    return (
        <div>
            <h1>Patient Health Records</h1>
            <form onSubmit={handleSubmit}>
                {patients.length > 0 && (
                    <>
                    <select
                        name="patient_id"
                        value={form.patient_id}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="">Select Patient</option>
                        {patients.map(patient => (
                            <option key={patient.id} value={patient.id}>
                                {patient.email}
                            </option>
                        ))}
                    </select>
                    <input
                        type="text"
                        name="record_type"
                        value={form.record_type}
                        onChange={handleInputChange}
                        placeholder="Record Type"
                        required
                    />
                    <textarea
                        name="description"
                        value={form.description}
                        onChange={handleInputChange}
                        placeholder="Description"
                        required
                    />
                    <button type="submit">{editingId ? 'Update Record' : 'Add Record'}</button>
                </>
                )}
            </form>
            <table>
                <thead>
                    <tr>
                        <th>Patient ID</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Record Type</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {records.map((record, index) => (
                        <tr key={index}>
                            <td>{record.patient_id}</td>
                            <td>{new Date(record.created_at).toDateString()}</td>
                            <td>{new Date(record.created_at).toLocaleTimeString()}</td>
                            <td>{record.record_type}</td>
                            <td>{record.description}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {/* <ul>
                {records.map(record => (
                    <li key={record.id}>
                        {record.description} - {record.record_type}
                        <button onClick={() => editRecord(record)}>Edit</button>
                    </li>
                ))}
            </ul> */}
        </div>
    );
};

export default HealthRecordsManager;