import React, { useState } from 'react';
import './Patient.css';
import Sidebar from "./SideBar"
import PHR from "./PHR";

const ViewPHR = () => {
  return (
    <div style={{marginLeft: "150px", padding: "1rem",width: "calc(100% - 150px)", minHeight: "100vh"}} className="dashboard-container">
      <Sidebar />
      <div className="main-content">
        <PHR/>
      </div>
    </div>
  );
};



export default ViewPHR;
