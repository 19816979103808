import React, { useState } from 'react';
import './SignIn.css';
import { useNavigate } from 'react-router-dom';
import Header1 from './Header';
import { loginUser } from '../endpoints';

const SignIn = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await loginUser({ email: username, password });
            const { id, role } = response.data;

            localStorage.setItem('userId', id);
            localStorage.setItem('userRole', role);
            switch (role) {
                case 'patient':
                    navigate('/patient-dashboard');
                    break;
                case 'hca':
                    navigate('/hca-dashboard');
                    break;
                case 'admin':
                    navigate('/admin-dashboard');
                    break;
                case 'pharmacist':
                    navigate('/pharmacist-dashboard');
                    break;
                case 'healthcareprovider':
                    navigate('/hcp-dashboard');
                    break;
                default:
                    console.error('Role not supported or undefined!');
                    alert('Login successful but the user role is not supported for navigation.');
                    break;
            }
        } catch (error) {
            console.error('Login failed:', error);
            alert('Invalid credentials or network error');
        }
    };

    return (
        <div>
            <Header1 />
            <div className="signin-container">
                <div className="signin-box">
                    <h2>Sign in to your account</h2>
                    <p>Enter your username & password to login</p>
                    <form onSubmit={handleSubmit}>
                        <div className="input-group">
                            <input
                                type="text"
                                placeholder="Username"
                                value={username}
                                onChange={e => setUsername(e.target.value)}
                                required
                            />
                        </div>
                        <div className="input-group">
                            <input
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit">Sign In</button>
                    </form>
                    <a href="#">Forgot password?</a>
                    <br />
                    <div className="signin-footer">
                        Don't have an account? <button onClick={() => navigate('/signup')}>Create Account</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignIn;
