import React, { useState, useEffect } from 'react';
import data from '../healthAdminData'
import './StaffCordination.css';

function StaffCordination() {
  const [staffData, setStaffData] = useState(data.staffData || []);

  return (
    <div className="staff-cordination">
      <h2>Healthcare Provider Accounts </h2>
      <div className='table-content'>
        <table className='user-table'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Role</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Access Level</th>
            </tr>
          </thead>
          <tbody>
            {staffData.map((data) => (
              <tr key={data.id}>
                <td>{data.id}</td>
                <td>{data.role}</td>
                <td>{data.name}</td>
                <td>{data.email}</td>
                <td>{data.phone}</td>
                <td>{data.accessLevel}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default StaffCordination;
