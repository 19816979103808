import React from 'react';
import StaffCordination from './StaffCordination'
import ComplianceOversight from './ComplianceOversight'
import FacilityManagement from './FacilityManagement'
import IncidentResponse from './IncidentResponse'
import Header from '../Header'
import "./index.css";
import PrescriptionManager from '../updated_comp/hcp/e-prescriptions';
import AppointmentManager from '../updated_comp/hcp/appointment_management';
import AppointmentTable from '../updated_comp/patient/upcoming_appointments'
import ProfessionalCollaboration from '../../components/updated_comp/hcp/forum';
import ReportComponent from '../updated_comp/hcp/analytics'
import HealthRecordsManager from '../updated_comp/patient/phr';

class HealthCareAdmin extends React.Component {

  state = {
    currentComponent: 'FacilityManagement',
    isSideNavOpen: false
  }

  handleToggle = (value) => {
    this.setState({ currentComponent: value });
  }

  toggleSideNav = () => {
    this.setState({ isSideNavOpen: !this.state.isSideNavOpen })
  }

  renderActiveComponent = (currentComponent) => {
    switch (currentComponent) {
      case "FacilityManagement": return <FacilityManagement />;
      case "StaffCordination": return <StaffCordination />;
      case "ComplianceOversight": return <ComplianceOversight />;
      case "IncidentResponse": return <IncidentResponse />;
      case "Prescriptions": return <PrescriptionManager />
      default: return <h1 />;
    }
  };

  render() {
    const { currentComponent, isSideNavOpen } = this.state;
    return (
      <div className="health-care-admin">
        <Header />
        <PrescriptionManager />
        <div style={{ padding: '200px' }}>
          <AppointmentManager />
          <AppointmentTable />
          <ReportComponent />
          <ProfessionalCollaboration />
          <HealthRecordsManager />
        </div>
      </div>
    );
  }
}

export default HealthCareAdmin;