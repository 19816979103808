import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SignUp.css';
import Header1 from './Header';
import { createUser } from '../endpoints';

const SignUp = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    role: ''
  });

  const navigate = useNavigate();
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await createUser(formData);
      console.log('User registered:', response.data);
      alert('User registered successfully!');

      localStorage.setItem('userId', response.data.id);
      localStorage.setItem('userRole', response.data.role);

      // Redirect user based on role

      switch (response.data.role) {
        case 'patient':
          navigate('/patient-dashboard');
          break;
        case 'admin':
          navigate('/admin-dashboard');
          break;
        case 'pharmacist':
          navigate('/pharmacist-dashboard');
          break;
        case 'healthcareprovider':
          navigate('/hcp-dashboard');
          break;
        case 'hca':
          navigate('/hca-dashboard');
          break;
        default:
          navigate('/');
          break;
      }
    } catch (error) {
      console.error('Error registering user:', error);
      alert('ERROR - Failed to register user!');
    }
  };

  return (
    <div>
      <Header1 />
      <div className="signup-container">
        <div className="signup-box">
          <h2>Create your account</h2>
          <p>Enter your personal details to create account</p>
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                required
              />
            </div>
            <div className="input-group">
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
                required
              />
            </div>

            <div className="input-group">
              <select name="role" value={formData.role} onChange={handleChange} required>
                <option value="">Select Role</option>
                <option value="patient">Patient</option>
                <option value="admin">Administrator</option>
                <option value="pharmacist">Pharmacist</option>
                <option value="healthcareprovider">Healthcare Provider</option>
                <option value="hca">Healthcare Administrator</option>

              </select>
            </div>
            <div className="input-group">
              <button type="submit">Create Account</button>
            </div>
          </form>
          <div className="signup-footer">
            Already have an account? <a href="/signin">Sign in</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;