import React from 'react';
import './Header.css';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();

  const navigateToSignIn = () => {
    navigate('/signin');
  };

  const navigateToSignUp = () => {
    navigate('/signup');
  };

  const logout = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('userRole');
    navigate('/');
  };


  const Communication = () => {
    navigate('/communication');
  };

  const navigateToDashboard = () => {
    const userRole = localStorage.getItem('userRole');
    if (userRole === 'patient') {
      navigate('/patient-dashboard');
    } else if (userRole === 'hca') {
      navigate('/hca-dashboard');
    } else if (userRole === 'pharmacist') {
      navigate('/pharmacist-dashboard');
    } else if (userRole === 'admin') {
      navigate('/admin-dashboard');
    } else if (userRole === 'healthcareprovider') {
      navigate('/hcp-dashboard');
    } else {
      console.error('Invalid user role:', userRole);
    }
  };

  return (
    <header className="header">
      <div className="logo">SMART HEALTH HUB</div>
      <nav className="navigation">

      </nav>
      <div className="auth-buttons">
        {localStorage.getItem('userId') ? (
          <>
            <button onClick={Communication} className="btn">Communication</button>

            <button onClick={navigateToDashboard} className="btn dashboard">Dashboard</button>
            <button onClick={logout} className="btn logout">Logout</button>
          </>
        ) : (
          <>
            <a href="/" className="nav-link">Home</a>
            <a href="https://sxp0664.uta.cloud/" className="nav-link">Blog</a>
            <a onClick={() => navigate('/about')} className="btn nav-link">About Us</a>
            <a onClick={() => navigate('/contactus')} className="nav-link">Contact Us</a>
            <button onClick={navigateToSignIn} className="btn login">Log In</button>
            <button onClick={navigateToSignUp} className="btn sign-up">Sign Up</button>
          </>
        )}
      </div>
    </header>
  );
}

export default Header;
