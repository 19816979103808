// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import '../../../styles/patient.css';

// const MedicationReminders = () => {
//     const [reminders, setReminders] = useState([]);
//     const [prescriptionId, setPrescriptionId] = useState('');
//     const [reminderTime, setReminderTime] = useState('');
//     const [status, setStatus] = useState('');
//     const [loading, setLoading] = useState(true);

//     useEffect(() => {
//         fetchReminders();
//     }, []);

//     const fetchReminders = async () => {
//         try {
//             const response = await axios.get('https://rxn9279.uta.cloud/api/medication-reminders');
//             const today = new Date().toISOString().slice(0, 10);
//             const todaysReminders = response.data.filter(reminder =>
//                 reminder.reminder_time.startsWith(today)
//             );
//             setReminders(todaysReminders);
//             setLoading(false);
//         } catch (error) {
//             console.error('Error fetching reminders', error);
//             setLoading(false);
//         }
//     };

//     const addReminder = async () => {
//         try {
//             const response = await axios.post('https://rxn9279.uta.cloud/api/medication-reminders', {
//                 prescription_id: prescriptionId,
//                 reminder_time: reminderTime,
//                 status: status
//             });
//             setReminders([...reminders, response.data]);
//         } catch (error) {
//             console.error('Error adding reminder', error);
//         }
//     };

//     const deleteReminder = async (id) => {
//         try {
//             await axios.delete(`https://rxn9279.uta.cloud/api/medication-reminders/${id}`);
//             setReminders(reminders.filter(reminder => reminder.id !== id));
//         } catch (error) {
//             console.error('Error deleting reminder', error);
//         }
//     };

//     return (
//         <div className="medication-reminders-container">
//             <h1>Today's Medication Reminders</h1>
//             {loading ? <p>Loading...</p> : (
//                 reminders.length > 0 ? (
//                     <ul className="reminders-list">
//                         {reminders.map(reminder => (
//                             <li key={reminder.id} className="reminder-item">
//                                 Prescription ID: {reminder.prescription_id}
//                                 <span>Time: {reminder.reminder_time}</span>
//                                 <span>Status: {reminder.status}</span>
//                                 <button className="button delete" onClick={() => deleteReminder(reminder.id)}>Delete</button>
//                             </li>
//                         ))}
//                     </ul>
//                 ) : <p>No reminders for today.</p>
//             )}
//         </div>
//     );
// };

// export default MedicationReminders;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../../styles/patient.css';


const MedicationReminders = () => {
    const [reminders, setReminders] = useState([]);

    useEffect(() => {
        fetch('https://rxn9279.uta.cloud/api/prescriptions')
            .then(response => response.json())
            .then(data => setReminders(data))
            .catch(error => console.error('Error fetching reminders:', error));
    }, []);

    const getTime = (reminder) => {
        const currentHour = new Date().getHours();
        if (reminder.dosage === 1 && currentHour >= 12) {
            return '';
        }
        if (reminder.dosage === 1) {
            return 'Morning';
        }
        return 'Evening';
    };

    return (
        <div>
            <h1>Reminders</h1>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
                {reminders.map(reminder => (
                    <li key={reminder.id} style={{ backgroundColor: '#f9f9f9', padding: '10px', marginBottom: '5px', borderRadius: '5px' }}>
                        <strong>Medication:</strong> {reminder.medication_name}<br />
                        <strong>Time:</strong> {getTime(reminder)}<br />
                    </li>
                ))}
            </ul>
        </div>
    );
};
export default MedicationReminders;
