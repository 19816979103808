import React, { useState, useEffect } from 'react';

const PrescriptionManager = () => {
    const [prescriptions, setPrescriptions] = useState([]);
    const [error, setError] = useState('');

    // Fetch all prescriptions
    const fetchPrescriptions = () => {
        fetch('https://rxn9279.uta.cloud/api/prescriptions')
            .then(response => response.json())
            .then(data => setPrescriptions(data))
            .catch(error => setError('Error fetching prescriptions'));
    };

    useEffect(() => {
        fetchPrescriptions();
    }, []);

    // Add a prescription
    const addPrescription = (prescriptionData) => {
        fetch('https://rxn9279.uta.cloud/api/prescriptions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(prescriptionData)
        })
            .then(response => response.json())
            .then(data => {
                setPrescriptions([...prescriptions, data]);
            })
            .catch(error => setError('Error adding prescription'));
    };

    // Update a prescription
    const updatePrescription = (id, updatedData) => {
        fetch(`https://rxn9279.uta.cloud/api/prescriptions/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedData)
        })
            .then(response => response.json())
            .then(data => {
                setPrescriptions(prescriptions.map(pres => pres.id === id ? data : pres));
            })
            .catch(error => setError('Error updating prescription'));
    };

    // Delete a prescription
    const deletePrescription = (id) => {
        fetch(`https://rxn9279.uta.cloud/api/prescriptions/${id}`, {
            method: 'DELETE'
        })
            .then(response => {
                if (response.ok) {
                    setPrescriptions(prescriptions.filter(pres => pres.id !== id));
                }
            })
            .catch(error => setError('Error deleting prescription'));
    };

    return (
        <div>
            <h1>Prescriptions</h1>
            {error && <p>{error}</p>}
            <ul>
                {prescriptions.map(prescription => (
                    <li key={prescription.id}>
                        {prescription.medication_name} - {prescription.dosage} - {prescription.refill_info} - {prescription.duration}
                    </li>

                ))}
            </ul>
        </div>
    );
};

export default PrescriptionManager;
