import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ProfessionalCollaboration() {
    const [collaborations, setCollaborations] = useState([]);
    const [form, setForm] = useState({ title: '', description: '', date: '', user_id: localStorage.getItem('userId') });
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        fetchCollaborations();
    }, []);

    const fetchCollaborations = async () => {
        const response = await axios.get('https://rxn9279.uta.cloud/api/professional-collaborations');
        setCollaborations(response.data);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setForm({ ...form, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const submissionForm = { ...form, user_id: localStorage.getItem('userId') };
        if (editing) {
            await axios.put(`https://rxn9279.uta.cloud/api/professional-collaborations/${form.id}`, submissionForm);
        } else {
            await axios.post('https://rxn9279.uta.cloud/api/professional-collaborations', submissionForm);
        }
        setForm({ title: '', description: '', date: '', user_id: localStorage.getItem('userId') });
        setEditing(false);
        fetchCollaborations();
    };

    const handleEdit = (collaboration) => {
        setForm({ ...collaboration, user_id: localStorage.getItem('userId') });
        setEditing(true);
    };

    const handleDelete = async (id) => {
        await axios.delete(`https://rxn9279.uta.cloud/api/professional-collaborations/${id}`);
        fetchCollaborations();
    };

    return (
        <div className="container">
            <form onSubmit={handleSubmit}>
                <label>Title:</label>
                <input
                    type="text"
                    name="title"
                    value={form.title}
                    onChange={handleInputChange}
                />
                <label>Description:</label>
                <textarea
                    name="description"
                    value={form.description}
                    onChange={handleInputChange}
                />
                <label>Date:</label>
                <input
                    type="date"
                    name="date"
                    value={form.date}
                    onChange={handleInputChange}
                />

                <button type="submit">{editing ? 'Update' : 'Add'}</button>
            </form>

            <h2>Collaborations</h2>
            <ul>
                {collaborations.map(collaboration => (
                    <li key={collaboration.id}>
                        {collaboration.title} - {collaboration.description}
                        {localStorage.getItem('userRole') !== 'patient' && (
                            <>
                                <button onClick={() => handleEdit(collaboration)}>Edit</button>
                                <button onClick={() => handleDelete(collaboration.id)}>Delete</button>
                            </>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default ProfessionalCollaboration;