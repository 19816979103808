import React, { useState } from "react";
import "./Patient.css";
import Header1 from "./Header";
import Sidebar from "./SideBar";
import PHR from "./PHR";
import MedicationReminders from "../components/updated_comp/patient/medication_reminders";
import AppointmentManager from "../components/updated_comp/patient/appointment_management";

import AppointmentTable from "../components/updated_comp/patient/upcoming_appointments";
import PrescriptionManager from "../components/updated_comp/patient/prescription_patient";
import SymptomChecker from "../components/updated_comp/patient/symptom_checker";
import Header from "../components/Header";
import ProfessionalCollaboration from "../components/updated_comp/hcp/forum";
import HealthRecordsManager from "./updated_comp/patient/phr";

const PatientDashboard = () => {
  const [appointments, setAppointments] = useState([]);
  const [currentTab, setCurrentTab] = useState("symptomChecker");

  const handleSchedule = () => {
    console.log("Schedule button clicked");
  };

  const onTabClick = (value) => {
    setCurrentTab(value);
  };

  return (
    <div className="main-content">
      <br />
      <Header />
      <div className="patient-tab">
        <button onClick={() => onTabClick("symptomChecker")} className="btn">
          Symptom Checker
        </button>
        <button onClick={() => onTabClick("medicalReminder")} className="btn">
          Medical Reminder
        </button>
        <button onClick={() => onTabClick("myAppointments")} className="btn">
          My Appointments
        </button>
        <button onClick={() => onTabClick("prescriptions")} className="btn">
          Prescriptions
        </button>
        <button onClick={() => onTabClick("healthRecord")} className="btn">
          Patient Health Record
        </button>
        <button onClick={() => onTabClick("collaboration")} className="btn">
          Collaborations
        </button>
      </div>
      {}
      <>
        {currentTab === "symptomChecker" ? (
          <SymptomChecker />
        ) : currentTab === "medicalReminder" ? (
          <MedicationReminders />
        ) : currentTab === "myAppointments" ? (
          <>
            <AppointmentManager />

            <h2>Upcoming Appointments</h2>
            <AppointmentTable appointments={appointments} />
          </>
        ) : currentTab === "prescriptions" ? (
          <PrescriptionManager />
        ) : currentTab === "collaboration" ? (
          <>
            <br />
            <ProfessionalCollaboration />
          </>
        ) : (
          <HealthRecordsManager />
        )}
      </>
    </div>
  );
};

export default PatientDashboard;
