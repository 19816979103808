import React, { useEffect, useState } from 'react';
import Header from '../Header';
import "./index.css";
import { format } from 'date-fns';

const PharmacistDashboard = () => {
    const [prescriptions, setPrescriptions] = useState([]);

    useEffect(() => {
        fetch('https://rxn9279.uta.cloud/api/prescriptions')
            .then(response => response.json())
            .then(data => setPrescriptions(data))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const handleDispense = (id) => {
        console.log(`Dispensing prescription with ID: ${id}`);
        fetch(`https://rxn9279.uta.cloud/api/prescriptions-update/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ dispense: 'dispensed' })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(() => {
                alert('Prescription has been dispensed successfully.');
                setPrescriptions(prevPrescriptions => prevPrescriptions.map(p => p.id === id ? { ...p, dispense: 'dispensed' } : p));
            })
            .catch(error => {
                console.error('Failed to dispense prescription:', error);
            });
    };


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'yyyy-MM-dd ');
    };

    return (
        <div>
            <Header />
            <h1>Pharmacist Dashboard</h1>
            {prescriptions.map(prescription => (
                <div key={prescription.id} className="prescription-item">
                    <p><strong>Medication Name:</strong> {prescription.medication_name}</p>
                    <p><strong>Dosage:</strong> {prescription.dosage}</p>
                    <p><strong>Dispensed on:</strong> {formatDate(prescription.updated_at)}</p>

                    <p><strong>Date of Prescription:</strong> {formatDate(prescription.created_at)}</p>

                    <p><strong>Dispense:</strong> {
                        prescription.dispense === "dispensed" ? "Dispensed" :
                            <button onClick={() => handleDispense(prescription.id)}>Dispense</button>
                    }</p>
                    <hr />
                </div>
            ))}
        </div>
    );
};

export default PharmacistDashboard;