import React, { useState } from 'react';
import data from '../healthAdminData'
import './IncidentResponse.css'

const IncidentResponse = () => {
  const incidentResponseData = data.incidentResponseData || [];
  return (
    <div className="incident-response">
      <h2>Incident Response</h2>
      <div className='table-content'>
        <table className='user-table'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Reported Problem</th>
              <th>Report Date</th>
              <th>Severity</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {incidentResponseData.length > 0 && incidentResponseData.map((responseData) => (
              <React.Fragment key={responseData.id}>
                <tr className='text-center'>
                  <td>{responseData.id}</td>
                  <td>{responseData.reportedProblem}</td>
                  <td>{responseData.reportDate}</td>
                  <td>{responseData.severity}</td>
                  <td>{responseData.status}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default IncidentResponse;
