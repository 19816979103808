import React, { useState, useEffect } from 'react';
import axios from 'axios';

function SystemConfiguration() {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [role, setRole] = useState('');
    const [status, setStatus] = useState('');

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        const response = await axios.get('https://rxn9279.uta.cloud/api/users');
        setUsers(response.data);
    };

    const selectUser = (user) => {
        setSelectedUser(user);
        setRole(user.role);
        setStatus(user.status);
        setEditMode(true);
    };

    const handleRoleChange = (e) => {
        setRole(e.target.value);
    };

    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };

    const saveChanges = async () => {
        await axios.put(`https://rxn9279.uta.cloud/api/users/${selectedUser.id}`, {
            ...selectedUser,
            role,
            status
        });
        fetchUsers();
        setEditMode(false);
        setSelectedUser(null);
    };

    return (
        <div>
            <h2>System Configuration</h2>
            {editMode && (
                <div>
                    <h3>Edit User</h3>
                    <p>Email: {selectedUser.email}</p>
                    <label>
                        Role:
                        <select value={role} onChange={handleRoleChange}>
                            <option value="patient">Patient</option>
                            <option value="healthcareprovider">Healthcare Provider</option>
                            <option value="admin">Admin</option>
                        </select>
                    </label>
                    <label>
                        Status:
                        <select value={status} onChange={handleStatusChange}>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                        </select>
                    </label>
                    <button onClick={saveChanges}>Save Changes</button>
                </div>
            )}
            <table>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.id}>
                            <td>{user.email}</td>
                            <td>{user.role}</td>
                            <td>{user.status}</td>
                            <td>
                                <button onClick={() => selectUser(user)}>Edit</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default SystemConfiguration;
