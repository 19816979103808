import React from 'react';
import { NavLink } from 'react-router-dom';
import './SideBar.css'; // Make sure to create this CSS file

const Sidebar = () => {
  return (
    <nav className="sidebar">
      <ul className="sidebar-nav">
        <li className="nav-item">
          <NavLink to="/" activeClassName="active" exact className="nav-link">
            Dashboard
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/appointments" activeClassName="active" className="nav-link">
            Appointments
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/patient" activeClassName="active" className="nav-link">
            Health Records
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/about" activeClassName="active" className="nav-link">
          Analysis
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/admin" activeClassName="active" className="nav-link">
          Admin
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/healthcareadmin" activeClassName="active" className="nav-link">
          Health Care Admin
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/pharmacist" activeClassName="active" className="nav-link">
            Pharmacist
          </NavLink>
        </li>
        {/* Add additional navigation items here */}
      </ul>
    </nav>
  );
};

export default Sidebar;
