import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ProviderManagement() {
    const [providers, setProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [form, setForm] = useState({ email: '', role: '', specialization: '' });

    useEffect(() => {
        fetchProviders();
    }, []);

    const fetchProviders = async () => {
        const response = await axios.get('https://rxn9279.uta.cloud/api/users');
        const filteredProviders = response.data.filter(user => user.role === 'healthcareprovider' || user.role === 'pharmacist');
        setProviders(filteredProviders);
    };

    const handleFormChange = (event) => {
        setForm({ ...form, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (selectedProvider) {
            await axios.put(`https://rxn9279.uta.cloud/api/users/${selectedProvider.id}`, form);
        } else {
            await axios.post('https://rxn9279.uta.cloud/api/users', form);
        }
        fetchProviders();
        setForm({ email: '', role: '', specialization: '' });
        setSelectedProvider(null);
    };

    const handleEdit = (provider) => {
        setSelectedProvider(provider);
        setForm(provider);
    };

    const handleDelete = async (id) => {
        await axios.delete(`https://rxn9279.uta.cloud/api/users/${id}`);
        fetchProviders();
    };

    return (
        <div>
            <h2>Healthcare Providers Management</h2>
            {selectedProvider && (
                <form onSubmit={handleSubmit}>
                    <input
                        type="email"
                        name="email"
                        value={form.email}
                        onChange={handleFormChange}
                        placeholder="Email"
                        required
                    />
                    <select name="role" value={form.role} onChange={handleFormChange} required>
                        <option value="healthcareprovider">Healthcare Provider</option>
                        <option value="pharmacist">Pharmacist</option>
                    </select>
                    <input
                        type="text"
                        name="specialization"
                        value={form.specialization}
                        onChange={handleFormChange}
                        placeholder="Specialization"
                    />
                    <button type="submit">Update Provider</button>
                </form>
            )}

            <table>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Specialization</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {providers.map(provider => (
                        <tr key={provider.id}>
                            <td>{provider.email}</td>
                            <td>{provider.role}</td>
                            <td>{provider.specialization || 'Not specified'}</td>
                            <td>
                                <button onClick={() => handleEdit(provider)}>Edit</button>
                                <button onClick={() => handleDelete(provider.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default ProviderManagement;
