import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import '../../styles/communication.css';
import Header from '../../components/Header';

const Communication = () => {
    const [users, setUsers] = useState([]);
    const [currentChat, setCurrentChat] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const messageEndRef = useRef(null);
    const userId = localStorage.getItem('userId');

    useEffect(() => {
        const fetchUsers = async () => {
            const response = await axios.get('https://sxs6952.uta.cloud/users');
            setUsers(response.data.map(user => ({
                id: user.id,
                username: user.email.split('@')[0],
                role: user.role,
            })));
        };
        fetchUsers();
    }, []);

    useEffect(() => {
        if (currentChat) {
            fetchChats();
        }
    }, [currentChat]);

    const fetchChats = async () => {
        const response = await axios.post('https://sxs6952.uta.cloud/fetch-chats', {
            senderId: userId,
            receiverId: currentChat
        });
        setMessages(response.data);
        scrollToBottom();
    };

    const handleSendMessage = async () => {
        if (!newMessage.trim()) return;
        const response = await axios.post('https://sxs6952.uta.cloud/post-chat', {
            senderId: userId,
            receiverId: currentChat,
            chatText: newMessage
        });
        setMessages(response.data);
        setNewMessage('');
        scrollToBottom();
    };

    const scrollToBottom = () => {
        messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const getReceiverName = () => {
        const user = users.find(user => user.id === currentChat);
        return user ? user.username : '';
    };

    return (
        <>
            <Header />
            <div className="chat-container">
                <div className="contacts-card card">
                    <ul className="list-reset">
                        {users.map(user => (
                            <li key={user.id} onClick={() => setCurrentChat(user.id)}
                                className={`contact-item ${user.id === currentChat ? 'active' : ''}`}>
                                {user.username} ({user.role})
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="chat-card card">
                    <div className="chat-header">{currentChat && `Chatting with ${getReceiverName()}`}</div>
                    <div className="chat-messages">
                        {messages.map((msg, index) => (
                            <div key={index} className={msg.sender_id === userId ? 'message sent' : 'message received'}>
                                <span className="message-bubble">{msg.chat_text}</span>
                            </div>
                        ))}
                        <div ref={messageEndRef} />
                    </div>
                    <div className="new-message-area">
                        <input type="text" value={newMessage} onChange={e => setNewMessage(e.target.value)} className="input-field" />
                        <button onClick={handleSendMessage} className="send-button">Send</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Communication;
