import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp'
import PatientDashboard from "./components/Patient"
import Doctor from "./components/Doctor"
import PHR from "./components/PHR";
import NewPrescription from "./components/Newprescription";
import ContactUs from "./components/Contact";
import About from "./components/About";
import Appointment from './components/Appointment';
import { BrowserRouter } from 'react-router-dom';
import Prescriptions from './components/Prescriptons';
import ViewPHR from './components/ViewPHR';
import Appointments from './components/ManageAppintments';
import AdminPage from "./components/updated_comp/admin/admin-dashboard";
import HealthCareAdmin from "./components/HealthCareAdmin/hcp-dashboard";
import Phamacist from './components/Pharmacist';
import MedicationReminders from './components/updated_comp/patient/medication_reminders';
import PharmacistDashboard from './components/Pharmacist/pharmacist-dashboard';
import Communication from '../src/components/updated_comp/communication';

import HealthAdminDashboard from '../src/components/updated_comp/hca'

function App() {
  return (

    <Router>
      <Routes>

        <Route path="/" element={<Home />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/doctor" element={<Doctor />} />
        <Route path="/phr" element={<PHR />} />
        <Route path="/new-prescription" element={<NewPrescription />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/about" element={<About />} />
        <Route path="/new-appointment" element={<Appointment />} />
        <Route path="/prescriptions" element={<Prescriptions />} />
        <Route path="/viewphr" element={<ViewPHR />} />
        <Route path="/appointments" element={<Appointments />} />
        <Route path="/" exact component={PatientDashboard} />
        <Route path="/medication-reminders" component={MedicationReminders} />
        <Route path="/patient-dashboard" element={<PatientDashboard />} />
        <Route path="/hcp-dashboard" element={<HealthCareAdmin />} />
        <Route path='/communication' element={<Communication />} />
        <Route path="/hca-dashboard" element={<HealthAdminDashboard />} />

        <Route path="/pharmacist-dashboard" element={<PharmacistDashboard />} />


        <Route path="/admin-dashboard" element={<AdminPage />} />


      </Routes>
    </Router >

  );
}

export default App;
