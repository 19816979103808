import React, { useEffect, useState } from 'react';
import '../../styles/hca.css';

import Header from '../../components/Header';

const HealthAdminDashboard = () => {

    const [complianceOversights, setComplianceOversights] = useState([]);
    const [newOversight, setNewOversight] = useState({ reported_date: '', severity: '', status: '' });
    const [editData, setEditData] = useState({});
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForms, setShowEditForms] = useState({});
    const [incidentResponses, setIncidentResponses] = useState([]);
    const [newIncident, setNewIncident] = useState({ reported_problem: '', report_date: '', severity: '', status: '' });
    const [editIncidentData, setEditIncidentData] = useState({});
    const [showAddIncidentForm, setShowAddIncidentForm] = useState(false);
    const [showEditIncidentForms, setShowEditIncidentForms] = useState({});
    const [users, setUsers] = useState([]);
    const [facilities, setFacilities] = useState([]);
    const [newFacility, setNewFacility] = useState({ name: '', location: '', services_offered: '', operational_status: '' });


    useEffect(() => {
        fetchComplianceOversights();
        fetchIncidentResponses();
        fetchUsers();
        fetchFacilities();

    }, []);
    const fetchFacilities = async () => {
        try {
            const response = await fetch('https://rxn9279.uta.cloud/api/facilities');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setFacilities(data);
        } catch (error) {
            console.error('Failed to fetch facilities:', error);
        }
    };





    const fetchUsers = async () => {
        try {
            const response = await fetch('https://rxn9279.uta.cloud/api/users');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setUsers(data.filter(user => user.role === 'healthcareprovider' || user.role === 'pharmacist'));
        } catch (error) {
            console.error('Failed to fetch users:', error);
        }
    };

    const handleAddInputChange = (e) => {
        setNewOversight({ ...newOversight, [e.target.name]: e.target.value });
    };

    const addComplianceOversight = async () => {
        if (!newOversight.status) {
            alert('Status is required');
            return;
        }
        try {
            const response = await fetch('https://rxn9279.uta.cloud/api/compliance-oversights', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newOversight)
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            fetchComplianceOversights();
            setNewOversight({ reported_date: '', severity: '', status: '' });
        } catch (error) {
            console.error('Failed to add compliance oversight:', error);
        }
    };

    const handleInputChange = (e) => {
        setNewFacility({ ...newFacility, [e.target.name]: e.target.value });
    };

    const addFacility = async () => {
        try {
            const response = await fetch('https://rxn9279.uta.cloud/api/facilities', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newFacility)
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            fetchFacilities();
            setNewFacility({ name: '', location: '', services_offered: '', operational_status: '' });
            setShowAddForm(false);
        } catch (error) {
            console.error('Failed to add facility:', error);
        }
    };

    const updateFacility = async (id) => {
        const facilityToUpdate = editData[id];
        try {
            const response = await fetch(`https://rxn9279.uta.cloud/api/facilities/${id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(facilityToUpdate)
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            fetchFacilities();
            setShowEditForms({ ...showEditForms, [id]: false });
        } catch (error) {
            console.error('Failed to update facility:', error);
        }
    };

    const deleteFacility = async (id) => {
        try {
            const response = await fetch(`https://rxn9279.uta.cloud/api/facilities/${id}`, {
                method: 'DELETE',
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            fetchFacilities();
        } catch (error) {
            console.error('Failed to delete facility:', error);
        }
    };

    const handleEditInputChange = (id, e) => {
        const updatedEditData = { ...editData, [id]: { ...editData[id], [e.target.name]: e.target.value } };
        setEditData(updatedEditData);
    };

    const fetchIncidentResponses = async () => {
        try {
            const response = await fetch('https://rxn9279.uta.cloud/api/incident-responses');
            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();
            setIncidentResponses(data);
        } catch (error) {
            console.error('Failed to fetch incident responses:', error);
        }
    };

    const handleIncidentInputChange = (e) => {
        setNewIncident({ ...newIncident, [e.target.name]: e.target.value });
    };

    const handleEditIncidentInputChange = (id, e) => {
        const updatedData = { ...editIncidentData, [id]: { ...editIncidentData[id], [e.target.name]: e.target.value } };
        setEditIncidentData(updatedData);
    };

    const addIncidentResponse = async () => {
        if (!newIncident.status) {
            alert('Status is required');
            return;
        }
        try {
            const response = await fetch('https://rxn9279.uta.cloud/api/incident-responses', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newIncident)
            });
            if (!response.ok) throw new Error('Network response was not ok');
            fetchIncidentResponses();
            setNewIncident({ reported_problem: '', report_date: '', severity: '', status: '' });
            setShowAddIncidentForm(false);
        } catch (error) {
            console.error('Failed to add incident response:', error);
        }
    };

    const editIncidentResponse = async (id) => {
        const incidentToUpdate = editIncidentData[id];
        try {
            const response = await fetch(`https://rxn9279.uta.cloud/api/incident-responses/${id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(incidentToUpdate)
            });
            if (!response.ok) throw new Error('Network response was not ok');
            fetchIncidentResponses();
            setShowEditIncidentForms({ ...showEditIncidentForms, [id]: false });
        } catch (error) {
            console.error('Failed to edit incident response:', error);
        }
    };

    const deleteIncidentResponse = async (id) => {
        try {
            const response = await fetch(`https://rxn9279.uta.cloud/api/incident-responses/${id}`, {
                method: 'DELETE'
            });
            if (!response.ok) throw new Error('Network response was not ok');
            fetchIncidentResponses();
        } catch (error) {
            console.error('Failed to delete incident response:', error);
        }
    };

    const editComplianceOversight = async (id) => {
        const oversightToUpdate = editData[id];
        if (!oversightToUpdate.status) {
            alert('Status is required');
            return;
        }
        try {
            const response = await fetch(`https://rxn9279.uta.cloud/api/compliance-oversights/${id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(oversightToUpdate)
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            fetchComplianceOversights();
            setShowEditForms({ ...showEditForms, [id]: false });
        } catch (error) {
            console.error('Failed to edit compliance oversight:', error);
        }
    };


    const deleteComplianceOversight = async (id) => {
        try {
            const response = await fetch(`https://rxn9279.uta.cloud/api/compliance-oversights/${id}`, {
                method: 'DELETE'
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            fetchComplianceOversights();
        } catch (error) {
            console.error('Failed to delete compliance oversight:', error);
        }
    };


    const fetchComplianceOversights = async () => {
        try {
            const response = await fetch('https://rxn9279.uta.cloud/api/compliance-oversights');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setComplianceOversights(data);
        } catch (error) {
            console.error('Failed to fetch compliance oversights:', error);
        }
    };
    const facilityManagement = [
        { id: 1, firstName: 'John', lastName: 'Doe', facilityName: 'Central Hospital', services: 'General Care', status: 'Active' },
        { id: 2, firstName: 'Jane', lastName: 'Smith', facilityName: 'West Clinic', services: 'Pediatrics', status: 'Inactive' }
    ];

    const staffData = [
        { id: 1, role: 'Nurse', name: 'Alice Johnson', email: 'alice@example.com', phone: '555-1234', accessLevel: 'High' },
        { id: 2, role: 'Administrator', name: 'Bob White', email: 'bob@example.com', phone: '555-5678', accessLevel: 'Medium' }
    ];

    const incidentResponseData = [
        { id: 1, reported_problem: 'Data Breach', report_date: '2023-04-01', severity: 'High', status: 'Resolved' },
        { id: 2, reported_problem: 'Equipment Failure', report_date: '2023-04-02', severity: 'Medium', status: 'Pending' }
    ];

    return (
        <div className="health-admin-dashboard">
            <Header />
            <h1>Healthcare Administration Dashboard</h1>

            {/* Facility Management */}
            <div className="facility-management">
                <h1>Facility Management</h1>

                {showAddForm ? (
                    <div>
                        <input type="text" name="name" value={newFacility.name} onChange={handleInputChange} placeholder="Facility Name" />
                        <input type="text" name="location" value={newFacility.location} onChange={handleInputChange} placeholder="Location" />
                        <input type="text" name="services_offered" value={newFacility.services_offered} onChange={handleInputChange} placeholder="Services Offered" />
                        <select name="operational_status" value={newFacility.operational_status} onChange={handleInputChange}>
                            <option value="">Select Status</option>
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                        </select>
                        <button onClick={addFacility}>Add Facility</button>
                        <button onClick={() => setShowAddForm(false)}>Cancel</button>
                    </div>
                ) : (
                    <button onClick={() => setShowAddForm(true)}>Add Facility</button>
                )}

                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Location</th>
                            <th>Services Offered</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {facilities.map((facility) => (
                            <tr key={facility.id}>
                                <td>{facility.name}</td>
                                <td>{facility.location}</td>
                                <td>{facility.services_offered}</td>
                                <td>{facility.operational_status}</td>
                                <td>
                                    {showEditForms[facility.id] ? (
                                        <div>
                                            <button onClick={() => updateFacility(facility.id)}>Save</button>
                                            <button onClick={() => setShowEditForms({ ...showEditForms, [facility.id]: false })}>Cancel</button>
                                        </div>
                                    ) : (
                                        <div>
                                            <button onClick={() => setShowEditForms({ ...showEditForms, [facility.id]: true })}>Edit</button>
                                            <button onClick={() => deleteFacility(facility.id)}>Delete</button>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Staff Coordination */}
            <div className="staff-cordination">
                <h2>Healthcare Provider Accounts</h2>
                <table className='user-table'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <tr key={user.id}>
                                <td>{user.id}</td>
                                <td>{user.email}</td>
                                <td>{user.role}</td>
                                <td>{user.status}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Compliance Oversight */}
            <div className="compliance-oversight">
                <h2>Compliance Oversight</h2>
                {showAddForm ? (
                    <div>
                        <input type="date" name="reported_date" value={newOversight.reported_date} onChange={handleAddInputChange} />
                        <input type="text" name="severity" value={newOversight.severity} onChange={handleAddInputChange} />
                        <select name="status" value={newOversight.status} onChange={handleAddInputChange}>
                            <option value="">Select Status</option>
                            <option value="Pending">Pending</option>
                            <option value="Resolved">Resolved</option>
                        </select>
                        <button onClick={addComplianceOversight}>Add</button>
                        <button onClick={() => setShowAddForm(false)}>Cancel</button>
                    </div>
                ) : (
                    <button onClick={() => setShowAddForm(true)}>Add Compliance Oversight</button>
                )}
                <ul>
                    {complianceOversights.map((issue, index) => (
                        <li key={index}>
                            <div>
                                {showEditForms[issue.id] ? (
                                    <div>
                                        <input type="date" name="reported_date" value={editData[issue.id]?.reported_date || issue.reported_date} onChange={(e) => handleEditInputChange(issue.id, e)} />
                                        <input type="text" name="severity" value={editData[issue.id]?.severity || issue.severity} onChange={(e) => handleEditInputChange(issue.id, e)} />
                                        <select name="status" value={editData[issue.id]?.status || issue.status} onChange={(e) => handleEditInputChange(issue.id, e)}>
                                            <option value="">Select Status</option>
                                            <option value="Pending">Pending</option>
                                            <option value="Resolved">Resolved</option>
                                        </select>
                                        <button onClick={() => editComplianceOversight(issue.id)}>Save</button>
                                        <button onClick={() => setShowEditForms({ ...showEditForms, [issue.id]: false })}>Cancel</button>
                                    </div>
                                ) : (
                                    <div>
                                        <h3>{issue.reported_problem}</h3>
                                        <p>Reported Date: {issue.reported_date}</p>
                                        <p>Severity: {issue.severity}</p>
                                        <p>Status: {issue.status}</p>
                                        <button onClick={() => setShowEditForms({ ...showEditForms, [issue.id]: true })}>Edit</button>
                                        <button onClick={() => deleteComplianceOversight(issue.id)}>Delete</button>
                                    </div>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            <div className="incident-response">
                <h2>Incident Response</h2>
                {showAddIncidentForm ? (
                    <div>
                        <input type="text" name="reported_problem" value={newIncident.reported_problem} onChange={handleIncidentInputChange} placeholder="Reported Problem" />
                        <input type="date" name="report_date" value={newIncident.report_date} onChange={handleIncidentInputChange} />
                        <input type="text" name="severity" value={newIncident.severity} onChange={handleIncidentInputChange} placeholder="Severity" />
                        <select name="status" value={newIncident.status} onChange={handleIncidentInputChange}>
                            <option value="">Select Status</option>
                            <option value="Pending">Pending</option>
                            <option value="Resolved">Resolved</option>
                        </select>
                        <button onClick={addIncidentResponse}>Add</button>
                        <button onClick={() => setShowAddIncidentForm(false)}>Cancel</button>
                    </div>
                ) : (
                    <button onClick={() => setShowAddIncidentForm(true)}>Add Incident Response</button>
                )}
                <ul>
                    {incidentResponses.map((incident, index) => (
                        <li key={index}>
                            <div>
                                {showEditIncidentForms[incident.id] ? (
                                    <div>
                                        <input type="text" name="reported_problem" value={editIncidentData[incident.id]?.reported_problem || incident.reported_problem} onChange={(e) => handleEditIncidentInputChange(incident.id, e)} />
                                        <input type="date" name="report_date" value={editIncidentData[incident.id]?.report_date || incident.report_date} onChange={(e) => handleEditIncidentInputChange(incident.id, e)} />
                                        <input type="text" name="severity" value={editIncidentData[incident.id]?.severity || incident.severity} onChange={(e) => handleEditIncidentInputChange(incident.id, e)} />
                                        <select name="status" value={editIncidentData[incident.id]?.status || incident.status} onChange={(e) => handleEditIncidentInputChange(incident.id, e)}>
                                            <option value="Pending">Pending</option>
                                            <option value="Resolved">Resolved</option>
                                        </select>
                                        <button onClick={() => editIncidentResponse(incident.id)}>Save</button>
                                        <button onClick={() => setShowEditIncidentForms({ ...showEditIncidentForms, [incident.id]: false })}>Cancel</button>
                                    </div>
                                ) : (
                                    <div>
                                        <h3>{incident.reported_problem}</h3>
                                        <p>Report Date: {incident.report_date}</p>
                                        <p>Severity: {incident.severity}</p>
                                        <p>Status: {incident.status}</p>
                                        <button onClick={() => setShowEditIncidentForms({ ...showEditIncidentForms, [incident.id]: true })}>Edit</button>
                                        <button onClick={() => deleteIncidentResponse(incident.id)}>Delete</button>
                                    </div>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div >
    );
};

export default HealthAdminDashboard;
