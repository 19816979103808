// ConsultationSection.js
import React from 'react';
import './ConsultationSection.css'; // Make sure to create a corresponding CSS file

const ConsultationSection = () => {
    return (
        <div className="consultation-section">
            <div className="consultation-content">
                <h2>Health Consultation for a Good Life</h2>
                <p>Health consultations are important for a good life as they give personalized help for each person's health needs. With expert advice, people can make better choices about their health, leading to a healthier lifestyle. By dealing with worries, giving tips on staying healthy, and supporting a whole-body approach, health consultations are vital for a happy and healthy life.</p>
            </div>
            <div className="consultation-image">
                {/* Place an image here */}
            </div>
        </div>
    );
};

export default ConsultationSection;
