import React from 'react';
import data from '../healthAdminData';
import './ComplianceOversight.css'

const ComplianceOversight = () => {
  const incidentResponseData = data.incidentResponseData || [];
  console.log(incidentResponseData);
  return (
    <div className="compliance-oversight">
      <h2>Compliance Oversight</h2>
          <ul>
            {incidentResponseData.length > 0 && incidentResponseData.map((responseData) => (
               <React.Fragment>
                <li>
                  <div>
                    <h3>{responseData.reportedIssue}</h3>
                    <p>Reported Date: {responseData.reportDate}</p>
                    <p>Severity: {responseData.severity}</p>
                    <p>Description: {responseData.description}</p>
                    <button className='toggle-buttons'>Resolve Issue</button>
                  </div>
                </li>
               </React.Fragment>
            ))}
          </ul>
          {/* </tbody>
        </table> */}
    </div>
  );
};

export default ComplianceOversight;
