import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../../styles/patient.css';

const AppointmentManager = () => {
    const [appointments, setAppointments] = useState([]);
    const [providers, setProviders] = useState([]);
    const [selectedProviderId, setSelectedProviderId] = useState('');
    const [appointmentTime, setAppointmentTime] = useState('');
    const [status, setStatus] = useState('Scheduled');
    const [loading, setLoading] = useState(true);
    const userId = localStorage.getItem('userId');

    useEffect(() => {
        fetchProvidersAndAppointments();
    }, []);

    const fetchProvidersAndAppointments = async () => {
        try {
            // Fetch providers
            const userResponse = await axios.get('https://rxn9279.uta.cloud/api/users');
            const healthcareProviders = userResponse.data.filter(user => user.role === 'patient');
            setProviders(healthcareProviders);

            // Fetch appointments
            const appointmentResponse = await axios.get('https://rxn9279.uta.cloud/api/appointments');
            setAppointments(appointmentResponse.data.filter(appointment => appointment.provider_id === userId));

            setLoading(false);
        } catch (error) {
            console.error('Error fetching data', error);
            setLoading(false);
        }
    };

    const addAppointment = async () => {
        if (!selectedProviderId || !appointmentTime) {
            alert("Please select a patient and set an appointment time.");
            return;
        }
        try {
            const response = await axios.post('https://rxn9279.uta.cloud/api/appointments', {
                patient_id: parseInt(userId),
                provider_id: parseInt(selectedProviderId),
                appointment_time: appointmentTime,
                status
            });
            setAppointments([...appointments, response.data]);
        } catch (error) {
            console.error('Error adding appointment', error);
        }
    };

    const deleteAppointment = async (id) => {
        try {
            await axios.delete(`https://rxn9279.uta.cloud/api/appointments/${id}`);
            setAppointments(appointments.filter(appointment => appointment.id !== id));
        } catch (error) {
            console.error('Error deleting appointment', error);
        }
    };

    return (
        <div>
            <h1>Schedule Appointment</h1>
            {loading ? <p>Loading...</p> : (
                <>
                    <select onChange={(e) => setSelectedProviderId(e.target.value)} value={selectedProviderId}>
                        <option value="">Select a Patient</option>
                        {providers.map(provider => (
                            <option key={provider.id} value={provider.id}>{provider.email}</option>
                        ))}
                    </select>
                    <input
                        type="datetime-local"
                        value={appointmentTime}
                        onChange={(e) => setAppointmentTime(e.target.value)}
                        placeholder="Appointment Time"
                    />
                    <button onClick={addAppointment}>Schedule Appointment</button>
                    <ul>
                        {appointments.map(appointment => (
                            <li key={appointment.id}>
                                Provider ID: {appointment.provider_id} - {appointment.appointment_time} - {appointment.status}
                                <button onClick={() => deleteAppointment(appointment.id)}>Cancel Appointment</button>
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </div>
    );
};

export default AppointmentManager;
