import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Pie } from 'react-chartjs-2';

const ReportComponent = () => {
    const [chartData, setChartData] = useState({
        labels: ['Appointments', 'Prescriptions'],
        datasets: [{
            label: 'Number of Records',
            data: [0, 0],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)'
            ],
            borderWidth: 1
        }]
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userId = localStorage.getItem('userId');
                const responses = await Promise.all([
                    axios.get('https://rxn9279.uta.cloud/api/appointments'),
                    axios.get('https://rxn9279.uta.cloud/api/prescriptions')
                ]);

                const appointments = responses[0].data;
                const prescriptions = responses[1].data;

                setChartData(prevData => ({
                    ...prevData,
                    datasets: [{
                        ...prevData.datasets[0],
                        data: [appointments.length, prescriptions.length]
                    }]
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            <h2>Provider Activity Report</h2>
            <Pie data={chartData} />
        </div>
    );
};

export default ReportComponent;
