import React, { useState } from 'react';

const SymptomChecker = () => {
    const [symptoms, setSymptoms] = useState('headache, fever');
    const [diagnosis, setDiagnosis] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchData = async () => {
        setIsLoading(true);
        setError(null);

        try {
            const response = await fetch('https://rxn9279.uta.cloud/api/symptoms', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ symptoms }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setDiagnosis(data.diagnosis);
        } catch (error) {
            setError('Failed to load diagnosis: ' + error.message);
        }

        setIsLoading(false);
    };

    const handleDiagnoseClick = () => {
        fetchData();
    };

    return (
        <div className="symptom-checker">
            <h1>Symptom Checker</h1>
            <input
                type="text"
                value={symptoms}
                onChange={(e) => setSymptoms(e.target.value)}
                placeholder="Enter symptoms separated by a comma"
            />
            <button onClick={handleDiagnoseClick}>Check Diagnosis</button>
            {isLoading ? (
                <p>Loading...</p>
            ) : error ? (
                <p className="error">{error}</p>
            ) : (
                diagnosis && (
                    <div className="diagnosis-result">
                        <h2>Diagnosis Result</h2>
                        <p>{diagnosis.choices[0].message.content}</p>
                    </div>
                )
            )}
        </div>
    );
};

export default SymptomChecker;
